import { gql } from "@apollo/client";

export const GET_CONSTELLATION = gql`
  query Constellations {
    constellations {
      id
      NAME
      SURNAME
      INITIALS
      CATEGORY
      COLOR
      NSTARS
    }
  }
`;

export const GET_LOCATION = gql`
  query Maps {
    maps {
      id
      Index
      OrgName
      NArts
      Lat
      Lon
      OrgDescription
      Country
      OrgWebsite
      Images
      OrgID
    }
  }
`;
