import { from } from "@apollo/client";
import { transform } from "framer-motion";
import React from "react";
import ReactDOM from "react-dom";
import { keyframes, Box } from "@chakra-ui/react";

function MoonLoader(props) {
  let color = props.color;

  const pulse = keyframes({
    "0%": {
      transform: "rotate(0deg)",
      background: "linear-gradient(to right top,#FB0712,#124FEB)",
    },

    "100%": {
      transform: "rotate(360deg)",
      background: "linear-gradient(to right top,#BA1018,#12EBAE)",
    },
  });

  return (
    <div
      class="backgroundContent"
      style={{
        display: "flex",
        height: "100vh",
        width: "100vw",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: color,
      }}
    >
      <Box
        class="loader"
        animation={`${pulse} 2s infinite`}
        style={{
          height: "200px",
          width: "200px",
          background: "linear-gradient(to right top,#FB0712,#124FEB )",
          borderRadius: "50%",
          position: "relative",

          transform: "all 2s",
        }}
        _after={{
          content: '""',
          position: "absolute",
          background: color,
          width: "180px",
          height: "180px",
          top: "20px",
          borderRadius: "50%",
        }}
      ></Box>
    </div>
  );
}

export default MoonLoader;
