import React from "react";

import { Link, useLocation, useSearchParams } from "react-router-dom";

import { Box, Button, Center, Heading, Grid, GridItem } from "@chakra-ui/react";
import Nav from "../Comnponents/Nav";
import BG from "../assets/bg.png";
function Home() {
  return (
    <Box
      width={"full"}
      height={"100vh"}
      backgroundColor={"#111a22"}
      bgAttachment="fixed"
      bgImage={BG}
      bgPosition="center"
      bgRepeat="no-repeat"
      bgSize="cover"
    >
      <Nav color="white" />
      <Center>
        <Heading
          as="h2"
          textAlign={"center"}
          size="2xl"
          color={"white"}
          mt={"200px"}
        >
          DEFINING OUR MULTIPLANETARY FUTURE, TOGETHER!
        </Heading>
      </Center>
      <Box m={"0 auto"} justifyItems={"center"}>
        <Grid templateColumns="repeat(2, 1fr)" gap={6}>
          <GridItem>
            <Link to={"/map"}>
              <Button
                bg="#3898ec"
                _hover={{ bg: "#3898ec", textColor: "black" }}
                textColor="white"
                p="8"
                mt="30px"
              >
                Submissions Map
              </Button>
            </Link>
          </GridItem>
          <GridItem>
            <Link to={"/constellation"}>
              <Button
                bg="#3898ec"
                _hover={{ bg: "#3898ec", textColor: "black" }}
                textColor="white"
                p="8"
                mt="30px"
              >
                Constellation
              </Button>
            </Link>
          </GridItem>
        </Grid>
      </Box>
    </Box>
  );
}

export default Home;
